* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.hidden {
  opacity: 0;
  filter: blur(1px);
  transform: translateY(50%);
  transition: all 1s;
}

.hiddenStationary {
  opacity: 0;
  filter: blur(1px);
  transform: translateY(0);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}
